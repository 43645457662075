/* latin-ext */
@font-face {
  font-family: '__DM_Sans_0dfae3';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/56d4c7a1c09c3371-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__DM_Sans_0dfae3';
  font-style: normal;
  font-weight: 100 1000;
  font-display: swap;
  src: url(/_next/static/media/7e6a2e30184bb114-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__DM_Sans_Fallback_0dfae3';src: local("Arial");ascent-override: 94.90%;descent-override: 29.66%;line-gap-override: 0.00%;size-adjust: 104.53%
}.__className_0dfae3 {font-family: '__DM_Sans_0dfae3', '__DM_Sans_Fallback_0dfae3', Inter;font-style: normal
}.__variable_0dfae3 {--font-family-default: '__DM_Sans_0dfae3', '__DM_Sans_Fallback_0dfae3', Inter
}

/* cyrillic-ext */
@font-face {
  font-family: '__Source_Code_Pro_ceb1b8';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/2053df8159b25386-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Source_Code_Pro_ceb1b8';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/9d9b9cae20d87d18-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Source_Code_Pro_ceb1b8';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/891487401855818d-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Source_Code_Pro_ceb1b8';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/64ea2a5aaa4dedd3-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Source_Code_Pro_ceb1b8';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/b63e4df112f8dce1-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Source_Code_Pro_ceb1b8';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/1a142ec2652f2d06-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Source_Code_Pro_ceb1b8';
  font-style: normal;
  font-weight: 200 900;
  font-display: swap;
  src: url(/_next/static/media/dba81c1208da12ee-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Source_Code_Pro_Fallback_ceb1b8';src: local("Arial");ascent-override: 73.11%;descent-override: 20.28%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_ceb1b8 {font-family: '__Source_Code_Pro_ceb1b8', '__Source_Code_Pro_Fallback_ceb1b8', Courier New;font-style: normal
}.__variable_ceb1b8 {--font-family-code: '__Source_Code_Pro_ceb1b8', '__Source_Code_Pro_Fallback_ceb1b8', Courier New
}

